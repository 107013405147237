import React, { useState, useRef } from "react";
import FormSelect from "./FormSelect";

import { useStoreContext } from "src/context/siteContext";
import { createAddress } from "../../api/account";
import countryOptions from "../../data/countryOptions.json";
import find from "lodash/find";
import cx from "classnames";

const COUNTRIES = countryOptions.map((c) => c.country);

const AddressNew = ({ refreshAddresses }) => {
  const { customerToken } = useStoreContext();
  const form = useRef(null);
  const [provinces, setProvinces] = useState(
    countryOptions[0].provinces.map((p) => p[0])
  );
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [formError, setFormError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, address1, address2, city, zip, isDefault } =
      form.current.elements;

    if (
      !firstName ||
      !lastName ||
      !address1 ||
      !address2 ||
      !city ||
      !selectedCountry ||
      !selectedProvince ||
      !zip
    ) {
      setFormError("Please add all values");
      return;
    }

    setFormError(null);

    try {
      const submitRequest = await createAddress({
        token: customerToken,
        isDefault: isDefault.checked,
        address: {
          firstName: firstName.value,
          lastName: lastName.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          country: selectedCountry,
          province: selectedProvince,
          zip: zip.value,
        },
      });

      const submitResult = await submitRequest.json();

      const { address } = submitResult;

      const { customerAddressCreate, customerDefaultAddressUpdate } = address;

      console.log(submitResult);

      if (customerAddressCreate) {
        // Create Non-Default Address
        console.log(customerAddressCreate);

        const { customerAddress, customerUserErrors = [] } =
          customerAddressCreate;

        if (customerUserErrors && customerUserErrors.length > 0) {
          console.log(customerUserErrors[0].message);
          setSubmitError(customerUserErrors[0].message);
        }

        if (customerAddress) {
          console.log(customerAddress);
          setSubmitError(null);
          refreshAddresses();
          setTimeout(() => {
            firstName.value = "";
            lastName.value = "";
            address1.value = "";
            address2.value = "";
            city.value = "";
            zip.value = "";
            isDefault.checked = false;

            setSelectedProvince(null);
            setSelectedCountry(null);
          }, 500);
        }
      }

      if (customerDefaultAddressUpdate) {
        // Create Address && Set as Default
        console.log(customerDefaultAddressUpdate);
        setSubmitError(null);
        refreshAddresses();
        setTimeout(() => {
          firstName.value = "";
          lastName.value = "";
          address1.value = "";
          address2.value = "";
          city.value = "";
          zip.value = "";
          isDefault.checked = false;

          setSelectedProvince(null);
          setSelectedCountry(null);
        }, 500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCountryChange = (value) => {
    const updatedProvinces = find(
      countryOptions,
      (option) => option.country === value
    );
    updatedProvinces &&
      setProvinces(updatedProvinces.provinces.map((p) => p[0]));

    setSelectedCountry(value);
  };

  const handleProvinceChange = (value) => {
    setSelectedProvince(value);
  };

  return (
    <div className="account__sidebar-block">
      {submitError && <p className="error">{submitError}</p>}
      {formError && <p className="error">{formError}</p>}
      <p>+ &nbsp; Create new address</p>
      <form className="mt1" onSubmit={handleSubmit} ref={form}>
        <div>
          <label>
            <input
              name="firstName"
              type="text"
              required={true}
              className="accounts__input py1 px1 s16 x"
              placeholder="First Name"
            />
          </label>
        </div>
        <div className="mt1">
          <label>
            <input
              name="lastName"
              type="text"
              required={true}
              className="accounts__input py1 px1 s16 x"
              placeholder="Last Name"
            />
          </label>
        </div>
        <div className="mt1">
          <label>
            <input
              name="address1"
              type="text"
              required={true}
              className="accounts__input py1 px1 s16 x"
              placeholder="Address"
            />
          </label>
        </div>
        <div className="mt1">
          <label>
            <input
              name="address2"
              type="text"
              className="accounts__input py1 px1 s16 x"
              placeholder="Apt, Unit, Suite, etc."
            />
          </label>
        </div>
        <div className="mt1">
          <label>
            <input
              name="city"
              type="text"
              required={true}
              className="accounts__input py1 px1 s16 x"
              placeholder="City"
            />
          </label>
        </div>
        <div className="pt1">
          <FormSelect
            name="country"
            placeholder="Country*"
            onChange={handleCountryChange}
            value={selectedCountry}
            className={cx("mt1", {
              error: formError,
            })}
            options={COUNTRIES}
          />
        </div>
        <div className="pt1">
          <FormSelect
            name="province"
            placeholder="Province*"
            onChange={handleProvinceChange}
            value={selectedProvince}
            className={cx("mt1", {
              error: formError,
            })}
            options={provinces}
          />
        </div>
        <div className="mt1">
          <label>
            <input
              name="zip"
              type="text"
              required={true}
              className="accounts__input py1 px1 s16 x"
              placeholder="Zip"
            />
          </label>
        </div>
        <div className="f aic mt1">
          <div className="account__form-check">
            <input
              type="checkbox"
              id="isDefault"
              name="isDefault"
              className="account__form-checkbox-input"
            />
            <span className="account__form-checkbox-mark" />
          </div>
          <label htmlFor="isDefault">Set as Default Address</label>
        </div>
        <button type="submit" className="cta cta--black mt1">
          Create Address
        </button>
      </form>
    </div>
  );
};
export default AddressNew;
