import React, { Component } from "react";
import classNames from "classnames";
import isBoolean from "lodash/isBoolean";

export default class FormSelect extends Component {
  static defaultProps = {
    openByDefault: false,
  };

  constructor(props) {
    super();
    this.state = {
      isOpen: props.openByDefault || false,
    };
  }

  componentDidMount = () => {
    global.addEventListener("click", this.onClickWindow);
  };

  componentWillUnmount = () => {
    global.removeEventListener("click", this.onClickWindow);
  };

  onClickWindow = () => {
    if (this.isMouseDownInside) {
      this.isMouseDownInside = false;
      return;
    }

    this.setState({
      isOpen: false,
    });

    this.isMouseDownInside = false;
  };

  onMouseDown = () => {
    this.isMouseDownInside = true;
  };

  onToggleOpen = () => {
    const { onToggleOpen } = this.props;
    this.setState({ isOpen: !this.state.isOpen });

    onToggleOpen && onToggleOpen(!this.state.isOpen);
  };

  onChange = (value) => {
    const { onChange } = this.props;
    this.setState({ isOpen: !this.state.isOpen });

    onChange && onChange(value);
  };

  render() {
    const {
      placeholder,
      value = null,
      options = [],
      isOpen,
      className,
      name,
      small,
      disabled,
    } = this.props;

    const statefulOpen = isBoolean(isOpen) ? isOpen : this.state.isOpen;

    const optionRows = (
      <div className="select--form__options-list ">
        {options.map((option, index) => (
          <button
            key={index}
            type="button"
            className={classNames("select--form__option", {
              "border-top": index > 0,
            })}
            onClick={() => this.onChange(option)}
          >
            {option}
          </button>
        ))}
      </div>
    );

    return (
      <div
        onMouseDown={this.onMouseDown}
        className={classNames("select--form", className)}
      >
        <select className="hidden" name={name}>
          {options.map((o, index) => (
            <option key={index} value={o}>
              {o}
            </option>
          ))}
        </select>
        <button
          type="button"
          className={classNames(
            "select--form__title accounts__input py1 px1 s16 x",
            {
              active: value !== null,
              open: statefulOpen,
            }
          )}
          disabled={disabled}
          onClick={this.onToggleOpen}
        >
          <span
            className={classNames("ellipsis", {
              o50: !value,
            })}
          >
            {value || placeholder || "Select"}
          </span>
          <span>↓</span>
        </button>
        {statefulOpen && optionRows}
      </div>
    );
  }
}
