import React, {Fragment, useEffect, useCallback, useState } from "react";
import DefaultAddress from './DefaultAddress';
import AddressNew from './AddressNew';
import AddressEdit from './AddressEdit';
import AddressTile from './AddressTile'

import cookie from "js-cookie";
import filter from 'lodash/filter';
import { useLoads } from "react-loads";

import { useStoreContext } from "src/context/siteContext";
import { UpdateCustomer } from 'src/utils/updateCustomer'
import { setCustomerInState } from 'src/context/siteContext'
import { removeGraphEdgesNode } from '../../utils/removeGraphEdgesNode';
import { getCustomer } from '../../api/account'

const Addresses = () => {
	const { customerToken } = useStoreContext();
	const [addresses, setAddresses] = useState([]);
	const [defaultAddress, setDefaultAddress] = useState(undefined)
	const [currentEditingAddress, setCurrentEditingAddress] = useState(undefined)

	const updateCustomerInState = setCustomerInState()

	const handleAddresses = useCallback(
		token =>
		getCustomer({token})
			.then(res => res.json())
			.then(res => {

				// override context address with Shopify data
				UpdateCustomer(res, res.customer.email, res.customer.defaultAddress)
				setTimeout(() => {
					updateCustomerInState()
				}, 200)


				const filteredList = filter(removeGraphEdgesNode(res.customer.addresses), (address) => address.id !== res.customer.defaultAddress.id)

				setDefaultAddress(res.customer.defaultAddress)
				setAddresses(filteredList)

			}),
		[]
	);

	const { error = {}, isRejected, isPending, isReloading, load } = useLoads(
		"handleAddresses",
		handleAddresses as any,
		{
			defer: true
		}
	);

	const refreshAddresses = () => {
		const token = customerToken || cookie.get("customer_token");
		load(token);
	}

	useEffect(() => {
		refreshAddresses()
	}, []);

	return (
    <Fragment>
      {(isPending || isReloading) && (
        <p className="b--black account__sidebar-block--padding">Loading</p>
      )}

      {isRejected && <div className="mt1 error">{error.message}</div>}

      {defaultAddress && (
        <DefaultAddress
          refreshAddresses={refreshAddresses}
          setCurrentEditingAddress={setCurrentEditingAddress}
          {...defaultAddress}
        />
      )}

      {addresses && addresses.length > 0 && (
        <Fragment>
          {addresses.map((location) => (
            <AddressTile
              key={location.id}
              {...location}
              customerToken={customerToken}
              setCurrentEditingAddress={setCurrentEditingAddress}
              refreshAddresses={refreshAddresses}
            />
          ))}
        </Fragment>
      )}

      {!isPending && !isReloading && (
        <AddressNew refreshAddresses={refreshAddresses} />
      )}

      <AddressEdit
        refreshAddresses={refreshAddresses}
        currentEditingAddress={currentEditingAddress}
        setCurrentEditingAddress={setCurrentEditingAddress}
      />
    </Fragment>
  );
}

export default Addresses;