import React, { Fragment } from "react";
import MobileTabDrawer from "./MobileTabDrawer";

import { useStoreContext } from "src/context/siteContext";
import { getAirPollution } from "../../hooks/useWeather";
import cx from "classnames";

const MobileAirPollution = ({
  currentNavTab,
  setCurrentNavTab,
  setCurrentTab,
}) => {
  const { customerAddress } = useStoreContext();

  const [isReady, airPollution] = getAirPollution({ customerAddress });

  let airQuality, values;

  if ("list" in airPollution) {
    switch (airPollution.list[0].main.aqi) {
      case 1:
        airQuality = "Good";
        break;
      case 2:
        airQuality = "Fair";
        break;
      case 3:
        airQuality = "Moderate";
        break;
      case 4:
        airQuality = "Poor";
        break;
      case 5:
        airQuality = "Very Poor";
        break;
      default:
        break;
    }

    const { co, no, no2, o3, so2, pm2_5, pm10, nh3 } =
      airPollution.list[0].components;

    values = {
      co,
      no,
      no2,
      o3,
      so2,
      pm2_5,
      pm10,
      nh3,
    };
  }

  return (
    <div className="account__mobile-tab">
      <MobileTabDrawer
        openByDefault={currentNavTab === "air-pollution"}
        isClosed={currentNavTab !== "air-pollution"}
        renderTrigger={({ isOpen, onClick }) => (
          <button
            onClick={() => {
              setCurrentNavTab("air-pollution");
              onClick();
            }}
            className={cx("account__mobile-tab-tile f jcc aic", {
              "bb--black": currentNavTab !== "air-pollution",
            })}
          >
            <img
              className="account__mobile-tab-icon"
              src="/images/icon-wind.svg"
              alt="Wind Icon"
            />
          </button>
        )}
      >
        {!customerAddress && (
          <div className="account__mobile-tab-content">
            <p>No address found.</p>
            <button
              className="button--reset"
              onClick={() => setCurrentTab("addresses")}
            >
              <span className="underline-on-hover">Set a new Address</span>
            </button>
          </div>
        )}
        {!isReady && customerAddress && (
          <div className="account__sidebar-block--padding">
            Looking up AQI...
          </div>
        )}
        {isReady && airPollution && customerAddress && (
          <Fragment>
            <div
              className={`account__aqi${
                airPollution.list
                  ? ` account__aqi--${airPollution.list[0].main.aqi}`
                  : ""
              }`}
            >
              <div className="account__sidebar-block--padding">
                <p>
                  Live AQI Index
                  <br />
                  {airQuality}
                </p>
              </div>
            </div>
            <div className="account__mobile-tab-content bt--black">
              <p>Polluting Gases</p>
              <ul className="mt1">
                <p>Carbon monoxide (CO): &nbsp;{values.co} μg/m3</p>
                <p>Nitrogen monoxide (NO): &nbsp;{values.no} μg/m3</p>
                <p>
                  Nitrogen dioxide (NO<sub>2</sub>): &nbsp;{values.no2} μg/m3
                </p>
                <p>
                  Ozone (O<sub>3</sub>): &nbsp;{values.o3} μg/m3
                </p>
                <p>
                  Sulphur dioxide (SO<sub>2</sub>): &nbsp;{values.so2} μg/m3
                </p>
                <p>
                  Particulates (PM<sub>2.5</sub>): &nbsp;{values.pm2_5} μg/m3
                </p>
                <p>
                  Particulates (PM<sub>10</sub>): &nbsp;{values.pm10} μg/m3
                </p>
                <p>
                  Ammonia(NH<sub>3</sub>): &nbsp;{values.nh3} μg/m3
                </p>
              </ul>
            </div>
          </Fragment>
        )}
      </MobileTabDrawer>
    </div>
  );
};

export default MobileAirPollution;
