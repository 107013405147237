import React, { useState, useEffect } from "react";
import MobileWeather from "./MobileWeather";
import MobileLiveFeed from "./MobileLiveFeed";
import MobileHoroscope from "./MobileHoroscope";
import MobileAirPollution from "./MobileAirPollution";
import MobileMercuryRetrograde from "./MobileMercuryRetrograde";

const MobilePortalNav = ({ setCurrentTab }) => {
  const [currentNavTab, setCurrentNavTab] = useState(null);

  return (
    <div className="bt--black f account__mobile-tab-list mt1 rel">
      <MobileWeather
        currentNavTab={currentNavTab}
        setCurrentNavTab={setCurrentNavTab}
        setCurrentTab={setCurrentTab}
      />
      {/* <MobileLiveFeed
        currentNavTab={currentNavTab}
        setCurrentNavTab={setCurrentNavTab}
        setCurrentTab={setCurrentTab}
      /> */}
      <MobileHoroscope
        currentNavTab={currentNavTab}
        setCurrentNavTab={setCurrentNavTab}
        setCurrentTab={setCurrentTab}
      />
      <MobileAirPollution
        currentNavTab={currentNavTab}
        setCurrentNavTab={setCurrentNavTab}
        setCurrentTab={setCurrentTab}
      />
      <MobileMercuryRetrograde
        currentNavTab={currentNavTab}
        setCurrentNavTab={setCurrentNavTab}
        setCurrentTab={setCurrentTab}
      />
    </div>
  );
};

export default MobilePortalNav;
