import cookie from "js-cookie"

export const UpdateCustomer = (res: {
  token: string
  customer: {
    firstName: string
    defaultAddress: any
  }
}, email: string) => {
  cookie.set("customer_token", res.token, { expires: 31 })
  cookie.set("customer_firstName", res.customer.firstName, {
    expires: 31,
  })
  if (res.customer.defaultAddress) {
    console.log('Updating default address')
    cookie.set("customer_defaultAddress", JSON.stringify(res.customer.defaultAddress), {
      expires: 31,
    })
  }
  cookie.set("customer_email", email, { expires: 31 })
}