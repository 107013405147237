import { useState, useEffect } from "react";
import fetch from "unfetch";

const getMercuryRetrograde = (now) => {
  const [isReady, setIsReady] = useState(false);
  const [isRetrograde, setIsRetrograde] = useState({});

  useEffect(() => {
    const requestMercury = async () => {
      const mercuryRequest = await fetch(
        `https://mercuryretrogradeapi.com?date=${now}`
      );
      const mercuryResult = await mercuryRequest.json();
      const { is_retrograde } = mercuryResult;
      setIsRetrograde(is_retrograde);
      setIsReady(true);
    };
    requestMercury();
  }, [now]);

  return [isReady, isRetrograde];
};

const getHoroscope = (sign) => {
  const [isReady, setIsReady] = useState(false);
  const [horoscope, setHoroscope] = useState({});

  useEffect(() => {
    const requestHoroscope = async () => {
      const horoscopeRequest = await fetch(
        `https://aztro.sameerkumar.website?sign=${sign}&day=today`,
        {
          method: "POST",
        }
      );
      const horoscopeResult = await horoscopeRequest.json();

      setHoroscope(horoscopeResult);
      setIsReady(true);
    };
    if (sign) {
      requestHoroscope();
    }
  }, [sign]);

  return [isReady, horoscope];
};

export { getMercuryRetrograde, getHoroscope };
