import React, { useState, useCallback } from "react"
import Helmet from "react-helmet"
import fetch from "unfetch"
import { Link } from "gatsby"
import { useLoads } from 'react-loads'

import { ErrorHandling } from 'src/utils/error'

export const ForgotPassword = ({ path }: { path: string }) => {
  const [formSuccess, setFormSuccess] = useState(false)
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleForgot = useCallback(
    (email) =>
      fetch(`/.netlify/functions/forgot-password`, {
        method: "POST",
        body: JSON.stringify({
          email
        }),
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw new Error(res.error)
          } else {
            setFormSuccess(true)
          }
        }),
    []
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    "handleForgot",
    handleForgot as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (form) {
      const { email } = form!.current!.elements
      load(email.value)
    }
  }

  return (
    <div>
      <Helmet title="Forgot Password — District Vision" />

      <div className="account__form-container x mxa">

        <h2 className="my0">Forgot your password?</h2>
        <form
          className="mt1"
          onSubmit={e => handleSubmit(e)}
          ref={form}>

          {isRejected && <ErrorHandling error={error.message} />}

          {formSuccess && (
            <div className="small studio mt1">
              Got it! Email coming your way now.
            </div>
          )}

          <div className="x container--s col aic jcc">
            <div className="pb1 mb1 pya">
              <label>
                <input id='email' name="email" type="text" required={true} className="accounts__input py1 px1 x s16 mb1" placeholder="Enter Email" />
              </label>
            </div>

            <button type="submit" className="block cta cta--black mt1">
              {(isPending ||
                isReloading) ? (
                <span>Loading</span>
              ): (
                <span>Request Reset</span>
              )}
            </button>

          </div>

          <p className="mya pt1 s14">
            Remember your password?{" "}
            <Link className="underline active" to="/account/login">
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  )
}
