import { navigate } from 'gatsby'
import cookie from 'js-cookie'
import React, { useEffect, useState } from "react"
import { RevealOnMount } from "../reveal-on-mount";

interface Props {
  component: React.ElementType
  path: string
}

const AuthWrapper = (props: Props) => {
  const { component: Component, path, ...rest } = props
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!cookie.get('customer_token') || !cookie.get('customer_email')) navigate('/account/login')
    setReady(true)
  }, [0]);

  return (
      <RevealOnMount location={path}>
        {ready ? <Component path={path} {...rest} /> : <span />}
      </RevealOnMount>
  )
}

export default AuthWrapper
