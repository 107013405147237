import React, { useState, useCallback } from "react";
import Helmet from "react-helmet";
import fetch from "unfetch";
import { Link, navigate } from "gatsby";
import { useLoads, useDeferredLoads } from "react-loads";
import { subscribe } from "klaviyo-subscribe";
import Timeout from "await-timeout";

import { RevealOnMount } from "../reveal-on-mount";
import { ErrorHandling } from "src/utils/error";

import { UpdateCustomer } from "src/utils/updateCustomer";
import { PasswordSchema } from "src/utils/schema";

export const Register = ({ path }: { path: string }) => {
  const [isPending, setIsPending] = useState(false);
  const [passwordField1, setPasswordField1] = useState("");
  const [passwordField2, setPasswordField2] = useState("");
  const [hasConsentedTerms, setHasConsentedTerms] = useState(false);
  const [hasOptInNewsletter, setHasOptInNewsletter] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const form = React.createRef() as React.RefObject<HTMLFormElement>;

  const handleRegister = async (event) => {
    event.preventDefault();
    console.log("handleRegister");
    if (!hasConsentedTerms) return;

    const { target } = event;

    const { email, password, firstName, lastName } = target;

    setAttempts(attempts + 1);

    if (!PasswordSchema.validate(passwordField1)) {
      setSignupError(
        "Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter and at least one number."
      );
      return;
    }

    if (passwordField1 !== passwordField2) {
      await Timeout.set(400);
      setSignupError("Passwords do not match.");
      return;
    }

    if (hasOptInNewsletter) {
      subscribe("S6Hvcc", email).then((result) => {
        // fire Facebook pixel event
        console.log(result);
        window?.fbq && window.fbq("trackCustom", "NewsletterSignup");
      });
    }

    setIsPending(true);
    setSignupError("");

    fetch(`/.netlify/functions/register`, {
      method: "POST",
      body: JSON.stringify({
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.error) {
          setSignupError(res.error);
        } else {
          UpdateCustomer(res, email);
          // re-hydrate the cart so it contains the email
          setTimeout(() => {
            window.location.pathname = "/account";
            setIsPending(false);
          }, 500);
        }
      });
  };

  return (
    <RevealOnMount location={path}>
      <Helmet title="Create Account — District Vision" />
      <div className="account__form-container x mxa">
        <div className="f jcb">
          <p>Sign Up</p>

          <Link className="s14 underline active block" to="/account/forgot">
            Forgot your password?
          </Link>
        </div>
        <p className="mt1">
          Please use the same email address that was used to purchase our
          courses.
        </p>
        <form onSubmit={handleRegister}>
          {isPending && <span>Loading</span>}

          {signupError && <ErrorHandling error={signupError} />}

          <div className="x container--s col">
            <div className="pya pb1">
              <label>
                <input
                  name="firstName"
                  type="text"
                  required={true}
                  className="accounts__input s16 mb0 x px1 py1"
                  placeholder="First Name"
                />
              </label>
            </div>
            <div className="pya pb1">
              <label>
                <input
                  name="lastName"
                  type="text"
                  required={true}
                  className="accounts__input s16 mb0 x px1 py1"
                  placeholder="Last Name"
                />
              </label>
            </div>
            <div className="pya pb1">
              <label>
                <input
                  name="email"
                  type="text"
                  required={true}
                  className="accounts__input s16 mb0 x px1 py1"
                  placeholder="Email Address"
                />
              </label>
            </div>
            <div className="pya pb1">
              <label>
                <input
                  name="password"
                  value={passwordField1}
                  onChange={(e) => setPasswordField1(e.target.value)}
                  type="password"
                  required={true}
                  className="accounts__input accounts__input--password s16 mb0 x px1 py1"
                  placeholder="Password"
                />
              </label>
            </div>
            <div className="pya pb1">
              <label>
                <input
                  name="passwordConfirm"
                  value={passwordField2}
                  onChange={(e) => setPasswordField2(e.target.value)}
                  type="password"
                  required={true}
                  className="accounts__input s16 mb1 x px1 py1"
                  placeholder="Verify Password"
                />
              </label>
            </div>
          </div>

          <div className="f aic account__form-check-wrapper">
            <div className="account__form-check">
              <input
                type="checkbox"
                id="newsletter"
                name="newsletter"
                className="account__form-checkbox-input"
                onChange={(e) => {
                  setHasOptInNewsletter(e.target.checked);
                }}
              />
              <span className="account__form-checkbox-mark" />
            </div>
            <label htmlFor="newsletter">Subscribe to Newsletter</label>
          </div>

          <div className="f aic mt1 account__form-check-wrapper">
            <div className="account__form-check">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                className="account__form-checkbox-input"
                onChange={(e) => {
                  setHasConsentedTerms(e.target.checked);
                }}
              />
              <span className="account__form-checkbox-mark" />
            </div>
            <label htmlFor="terms">
              I confirm that I have read and understood the membership policy
              and privacy policy.
            </label>
          </div>
          <button
            type="submit"
            className="cta cta--black mt1"
            disabled={!hasConsentedTerms || isPending}
          >
            {isPending ? <span>Loading</span> : <span>Sign Up</span>}
          </button>
          <p className="py1">
            Already have an account?{" "}
            <Link className="underline-on-hover" to="/account/login">
              Log in
            </Link>
          </p>
        </form>
      </div>
    </RevealOnMount>
  );
};
