import React, { useCallback, useState } from "react"
import Helmet from "react-helmet"
import { useDeferredLoads, useLoads } from "react-loads";
import fetch from "unfetch";
import { Link } from "gatsby";
// @ts-ignore
import { encode } from "shopify-gid";
import { navigate } from "@reach/router";
import Timeout from "await-timeout";
import cx from "classnames";
import { ErrorHandling } from "src/utils/error";
import { PasswordSchema } from "src/utils/schema";

const Activate = (props: { id: string; token: string }) => {
  const [passwordField1, setPasswordField1] = useState("");
  const [passwordField2, setPasswordField2] = useState("");
  const [attempts, setAttempts] = useState(0);

  const verifyAccount = useCallback(
    async (e) => {
      setAttempts(attempts + 1);

      if (passwordField1 !== passwordField2) {
        await Timeout.set(400);
        throw new Error("Passwords do not match.");
      }

      if (!PasswordSchema.validate(passwordField1)) {
        throw new Error(
          "Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter and at least one number."
        );
      }

      const body = {
        id: encode("Customer", props.id),
        input: {
          activationToken: props.token,
          password: passwordField1,
        },
      };

      const res = await fetch(`/.netlify/functions/activate`, {
        method: "POST",
        body: JSON.stringify(body),
      });

      try {
        const customer = await res.json();

        if (customer.error) {
          console.log(customer.error === "Account already enabled");
          if (customer.error === "Account already enabled") {
            const err = new Error(
              "Error: Account is already verified. Please login"
            );

            throw err;
          }
          const err = new Error(
            "Server Error: Account verification fetch response error."
          );

          throw err;
        } else if (!res.ok) {
          const err = new Error(
            "Server Error: Account verification fetch not OK."
          );

          throw err;
        }

        await Timeout.set(400);
        await navigate("/account/login");
      } catch (err) {
        console.log(error);
        throw err;
      }
      // hacky way to get it to run anew every time
      // and maybe in the future redirect them if they
      // need help.
    },
    [passwordField1, passwordField2, attempts]
  );

  const { error, load, isReloading, isPending } = useLoads(
    "verifyAccount",
    verifyAccount,
    {
      defer: true,
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    load();
  };
  return (
    <div>
      <Helmet title="Activate Account — District Vision" />

      <div className="account__form-container x mxa">
        <h5 className="pb0 caps sans ls">Activate Account</h5>
        <h2 className="my0">Almost there.</h2>
        <form className="mt1" onSubmit={handleSubmit}>
          <div className="x container--s al mxa">
            {error && !isReloading && <ErrorHandling error={error.message} />}

            {isPending && (
              <div className="f jcc p1">
                <span>Loading</span>
              </div>
            )}

            <div
              className={cx(
                "x container--s col mya",
                isPending || isReloading ? "invisible" : "visible"
              )}
            >
              <div className="pb1  x pya">
                <label>
                  <input
                    className="py1 px1  x s16 s16"
                    type="password"
                    value={passwordField1}
                    onChange={(e) => setPasswordField1(e.target.value)}
                    placeholder="Password"
                  />
                </label>
              </div>

              <div className="pb1 mb1 x pya">
                <label>
                  <input
                    className="py1 px1 x mb1 s16"
                    type="password"
                    value={passwordField2}
                    onChange={(e) => setPasswordField2(e.target.value)}
                    placeholder="Confirm Password"
                  />
                </label>
              </div>
            </div>
            <div className="ac x">
              <button type="submit" className="block cta cta--black mt1">
                Activate
              </button>

              <div className="container--m mya pt1 aic">
                <p className=" sans s14  pt1 ac">
                  Already have an account?{" "}
                  <Link className="underline active" to="/account/login">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Activate
