import React from "react";
import { Link } from "../link";
import CourseLink from "./CourseLink";
import { decode } from "shopify-gid";
import { getProductInfo } from "../../hooks/useProductLookup";

const OrderTileProduct = ({ id, title, variant, statusUrl }) => {
  const decodedProduct = variant.product.id.replace(
    "gid://shopify/Product/",
    ""
  );

  const [isReady, productInfo] = getProductInfo(Number(decodedProduct));

  const isCourse = variant?.product?.productType.toLowerCase() === "courses";

  return (
    <React.Fragment>
      {isReady && productInfo && (
        <div className="f account__order-line-item account__sidebar-block--padding">
          {productInfo.mainImage && (
            <img
              src={productInfo.mainImage.url}
              className="account__order-line-item-img"
              alt={title}
            />
          )}
          <div className="account__order-line-item-info">
            <p className="pb1">{title}</p>
            <div className="mt1">
              {isCourse ? (
                <CourseLink single {...variant} />
              ) : (
                <Link
                  type="internalLink"
                  to={`/products/${productInfo.slug}`}
                  className="cta cta--secondary cta--account f jcc aic"
                >
                  Buy It Again
                  <img
                    src="/images/buy-again.svg"
                    className="ml1 account__order-line-item-svg"
                    alt="Buy It Again"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderTileProduct;
