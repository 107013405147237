import React, { useState, useEffect } from "react";
import sanityClient from "@sanity/client";
import groq from "groq";

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.SANITY_API_TOKEN,
  useCdn: false,
});

const asset = groq`{
	_type,
	_key,
	alt,
	'dimensions': asset->metadata.dimensions,
	'url': asset->url,
}`;

export const getProductInfo = (productId) => {
  const [isReady, setIsReady] = useState(false);
  const [productInfo, setProductInfo] = useState({});

  const productQuery = groq`*[ _type == 'product' && content.shopify.productId == ${productId}] [0]{
		'mainImage': content.main.mainImage ${asset},
		'slug': content.main.slug.current,
		'title': content.main.title
	}`;

  useEffect(() => {
    const requestProduct = async () => {
      const request = await client.fetch(productQuery);
      setProductInfo(request);
      setIsReady(true);
    };
    requestProduct();
    return () => {};
  }, [productId]);

  return [isReady, productInfo];
};

export const getCourseInfo = (productId) => {
  const [isReady, setIsReady] = useState(false);
  const [courseInfo, setCourseInfo] = useState(null);

  const courseQuery = groq`*[ _type == 'product' && content.main.productCourse.isCourse == true && content.shopify.productId == ${productId}] [0]{
		'mainImage': content.main.mainImage ${asset},
		'courseType': content.main.productCourse.courseType,
		'courseInstructor': content.main.productCourse.courseInstructor,
		'courseDescription': content.main.productCourse.courseDescription.description,
		'courseCover': content.main.productCourse.courseCover ${asset},
		'courseContent': content.main.productCourse.courseContent[] {
			_key,
			_type,
			title,
			description,
			id,
			_type == 'courseAudio' => {
				'audioUrl': audio.asset->url,
			},
			_type == 'courseVideo' => {
				'video': video.asset->
			},
		},
		'slug': content.main.slug.current,
		'title': content.main.title
	}`;

  useEffect(() => {
    const requestProduct = async () => {
      const request = await client.fetch(courseQuery);
      setCourseInfo(request);
      setIsReady(true);
    };
    requestProduct();
  }, [productId]);

  return [isReady, courseInfo];
};


