import React, { useEffect, useState } from "react";
import { getMercuryRetrograde } from "../../hooks/useAstrology";

const MercuryRetrograde = () => {
  const [now, setNow] = useState("");
  const [isReady, isRetrograde] = getMercuryRetrograde(now);

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    setNow(formattedDate);
  }, []);

  return (
    <div className="b--black account__sidebar-block account__sidebar-block--padding">
      {!isReady && "Checking if Mercury is in Retrograde..."}
      {isReady && (
        <p>Is Mercury in Retrograde? &nbsp;{isRetrograde ? " Yes." : " No."}</p>
      )}
    </div>
  );
};

export default MercuryRetrograde;
