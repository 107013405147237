import React from "react";
import { Link } from "../link";
import { decode } from "shopify-gid";
import { getProductInfo } from "../../hooks/useProductLookup";

const CourseLink = ({ product, single }) => {
  const decodedProduct = product.id.replace("gid://shopify/Product/", "");

  const [isReady, productInfo] = getProductInfo(Number(decodedProduct));

  return (
    <div className="pb1">
      {isReady && productInfo && (
        <Link
          to={`/account?currentTab=courses&course=${decodedProduct}`}
          type="internalLink"
          className="cta x cta--black cta--account"
        >
          {single ? "Access Course" : `Access ${productInfo.title}`}
        </Link>
      )}
    </div>
  );
};

export default CourseLink;
