import React, { Fragment, useEffect, useState } from "react";
import MobileTabDrawer from "./MobileTabDrawer";

import { getHoroscope } from "../../hooks/useAstrology";
import cookie from "js-cookie";
import cx from "classnames";

const MobileHoroscope = ({
  currentNavTab,
  setCurrentNavTab,
  setCurrentTab,
}) => {
  const [sign, setSign] = useState(null);
  const [isReady, horoscope] = getHoroscope(sign);

  const zodiac = (day, month) => {
    let zodiacSign = "";

    if (
      (typeof parseInt(month) === "number" && parseInt(month) === 12) ||
      month.toLowerCase() === "december" ||
      month.toLowerCase() === "dec"
    ) {
      if (day < 22) zodiacSign = "Sagittarius";
      else zodiacSign = "Capricorn";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 1) ||
      month.toLowerCase() === "january" ||
      month.toLowerCase() === "jan"
    ) {
      if (day < 20) zodiacSign = "Capricorn";
      else zodiacSign = "Aquarius";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 2) ||
      month.toLowerCase() === "february" ||
      month.toLowerCase() === "feb"
    ) {
      if (day < 19) zodiacSign = "Aquarius";
      else zodiacSign = "Pisces";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 3) ||
      month.toLowerCase() === "march" ||
      month.toLowerCase() === "mar"
    ) {
      if (day < 21) zodiacSign = "Pisces";
      else zodiacSign = "aries";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 4) ||
      month.toLowerCase() === "april" ||
      month.toLowerCase() === "apr"
    ) {
      if (day < 20) zodiacSign = "Aries";
      else zodiacSign = "Taurus";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 5) ||
      month.toLowerCase() === "may"
    ) {
      if (day < 21) zodiacSign = "Taurus";
      else zodiacSign = "Gemini";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 6) ||
      month.toLowerCase() === "june" ||
      month.toLowerCase() === "jun"
    ) {
      if (day < 21) zodiacSign = "Gemini";
      else zodiacSign = "Cancer";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 7) ||
      month.toLowerCase() === "july" ||
      month.toLowerCase() === "jul"
    ) {
      if (day < 23) zodiacSign = "Cancer";
      else zodiacSign = "Leo";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 8) ||
      month.toLowerCase() === "august" ||
      month.toLowerCase() === "aug"
    ) {
      if (day < 23) zodiacSign = "Leo";
      else zodiacSign = "Virgo";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 9) ||
      month.toLowerCase() === "september" ||
      month.toLowerCase() === "sep" ||
      month.toLowerCase() === "sept"
    ) {
      if (day < 23) zodiacSign = "Virgo";
      else zodiacSign = "Libra";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 10) ||
      month.toLowerCase() === "october" ||
      month.toLowerCase() === "oct"
    ) {
      if (day < 23) zodiacSign = "Libra";
      else zodiacSign = "Scorpio";
    } else if (
      (typeof parseInt(month) === "number" && parseInt(month) === 11) ||
      month.toLowerCase() === "november" ||
      month.toLowerCase() === "nov"
    ) {
      if (day < 22) zodiacSign = "scorpio";
      else zodiacSign = "Sagittarius";
    }

    return zodiacSign;
  };

  const handleSetBirthday = (e) => {
    e.preventDefault();

    const { date, month } = e.target;
    const zodiacSign = zodiac(Number(date.value), month.value);

    setSign(zodiacSign);

    cookie.set("customer_zodiac", zodiacSign);
  };

  useEffect(() => {
    const zodiacSign = cookie.get("customer_zodiac") || sign;
    setSign(zodiacSign);
  }, []);

  const zodiacIcons = {
    Capricorn: "♑️",
    Aquarius: "♒️",
    Pisces: "♓️",
    Aries: "♈️",
    Taurus: "♉️",
    Gemini: "♊️",
    Cancer: "♋️",
    Leo: "♌️",
    Virgo: "♍️",
    Libra: "♎️",
    Scorpio: "♏️",
    Sagittarius: "♐️",
  };

  return (
    <div className="account__mobile-tab">
      <MobileTabDrawer
        openByDefault={currentNavTab === "horoscope"}
        isClosed={currentNavTab !== "horoscope"}
        renderTrigger={({ isOpen, onClick }) => (
          <button
            onClick={() => {
              setCurrentNavTab("horoscope");
              onClick();
            }}
            className={cx("account__mobile-tab-tile f jcc aic", {
              "bb--black": currentNavTab !== "horoscope",
            })}
          >
            <img
              className="account__mobile-tab-icon"
              src="/images/icon-moon.svg"
              alt="Horoscope Icon"
            />
          </button>
        )}
      >
        <div className="account__mobile-tab-content">
          {!sign && (
            <Fragment>
              <p className="">No Birthday Found.</p>
              <div className="mt1">
                <p>Enter your birthday for your horoscope</p>

                <form className="mt1" onSubmit={handleSetBirthday}>
                  <div className="f mb1">
                    <label className="mr1">
                      <input
                        type="text"
                        name="month"
                        id="month"
                        className="accounts__input py1 px1 s16 x"
                        placeholder="12"
                      />
                    </label>
                    <label>
                      <input
                        type="text"
                        name="date"
                        id="date"
                        className="accounts__input py1 px1 s16 x"
                        placeholder="31"
                      />
                    </label>
                  </div>
                  <button type="submit" className="cta cta--black">
                    Get Horoscope
                  </button>
                </form>
              </div>
            </Fragment>
          )}
          {!isReady && sign && (
            <div className="account__sidebar-block--padding">
              Loading your Horoscope...
            </div>
          )}
          {isReady && horoscope && sign && (
            <Fragment>
              <div className=" f jcb">
                <p>
                  {zodiacIcons[sign]} &nbsp; {sign}
                </p>
                <p>{horoscope.date_range}</p>
              </div>

              <p className="mt1">{horoscope.description}</p>

              <p className="mt1">
                Lucky Number: &nbsp; {horoscope.lucky_number}
              </p>
              <p>Mood: &nbsp; {horoscope.mood}</p>
            </Fragment>
          )}
        </div>
      </MobileTabDrawer>
    </div>
  );
};

export default MobileHoroscope;
