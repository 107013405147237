import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export const removeGraphEdgesNode = res => {
	if (isArray(res)) {
		return res.map(v => removeGraphEdgesNode(v));
	} else if (isObject(res)) {
		if (res.edges) {
			return removeGraphEdgesNode(res.edges);
		} else if (res.node) {
			return removeGraphEdgesNode(res.node);
		} else {
			return mapValues(res, removeGraphEdgesNode);
		}
	} else {
		return res;
	}
};