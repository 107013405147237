import React, { useState, useRef, useEffect } from "react";
import isBoolean from "lodash/isBoolean";

const MobileTabDrawer = ({
  renderTrigger = ({ onClick }) => <button onClick={onClick} />,
  transition = "height 400ms cubic-bezier(.12,.67,.53,1)",
  openByDefault = false,
  open,
  isClosed,
  children,
}) => {
  const heightEl = useRef(null);
  const [isOpen, setIsOpen] = useState(openByDefault || !isClosed);
  const [height, setHeight] = useState(0);
  const statefulOpen = isBoolean(open) ? open : isOpen;

  const onResize = () => {
    if (!heightEl.current) return;

    setHeight(heightEl.current.getBoundingClientRect().height);
  };

  const onClickTrigger = () => {
    onResize();
    setIsOpen(!isOpen);
  };

  // Remeasure height on resize
  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  // Remeasure height on toggle
  useEffect(onResize, [statefulOpen]);

  useEffect(() => {
    if (isClosed) setIsOpen(false);
  }, [isClosed]);

  return (
    <React.Fragment>
      {renderTrigger({
        onClick: onClickTrigger,
        isOpen: statefulOpen,
      })}
      <div
        style={{
          height: statefulOpen ? height : 0,
          transition,
        }}
        className="abs left bb--black of--hidden"
      >
        <div ref={heightEl}>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default MobileTabDrawer;
