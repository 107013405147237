import React, { Fragment } from "react";
import OrderTile from "./OrderTile";
import { Link } from "gatsby";

export const Orders = ({ isLoading, orders = [] }) => {
  return (
    <div className="container--m mxa x">
      <div className="x al mya">
        {isLoading && "Loading..."}
        {!isLoading && (
          <Fragment>
            {orders.length > 0 ? (
              <Fragment>
                {orders.map(
                  (order) =>
                    order.lineItems[0].variant && (
                      <OrderTile key={order.orderNumber} {...order} />
                    )
                )}
              </Fragment>
            ) : (
              <div>
                <p>
                  No orders yet.{" "}
                  <span className="underline-on-hover">
                    <Link to="/shop"> Shop Here</Link>
                  </span>
                </p>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};
