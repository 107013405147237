import React, { useEffect, useState, useRef } from "react";
import MobileTabDrawer from "./MobileTabDrawer";

import { useStoreContext } from "src/context/siteContext";
import { format } from "date-fns";
import { getWeather } from "../../hooks/useWeather";
import { useInterval } from "react-use";
import cx from "classnames";

const MobileWeather = ({ currentNavTab, setCurrentNavTab, setCurrentTab }) => {
  const { customerAddress } = useStoreContext();
  const [now, setNow] = useState(Date());
  const [isReady, weather] = getWeather({ customerAddress });

  useEffect(() => {
    setNow(new Date());
  }, []);

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  return (
    <div className="account__mobile-tab">
      <MobileTabDrawer
        openByDefault={currentNavTab === "weather"}
        isClosed={currentNavTab !== "weather"}
        renderTrigger={({ isOpen, onClick }) => (
          <button
            onClick={() => {
              setCurrentNavTab("weather");
              onClick();
            }}
            className={cx("account__mobile-tab-tile f jcc aic", {
              "bb--black": currentNavTab !== "weather",
            })}
          >
            <img
              className="account__mobile-tab-icon"
              src="/images/icon-weather.svg"
              alt="Weather Icon"
            />
          </button>
        )}
      >
        {!customerAddress && (
          <div className="account__mobile-tab-content">
            <p>No address found.</p>
            <button
              className="button--reset"
              onClick={() => setCurrentTab("addresses")}
            >
              <span className="underline-on-hover">Set a new Address</span>
            </button>
          </div>
        )}
        {!isReady && customerAddress && (
          <div className="account__mobile-tab-content">Loading Weather...</div>
        )}
        {isReady && weather && customerAddress && (
          <div className={cx("account__mobile-tab-content", {})}>
            <div className="f aic">
              {weather.weather[0].icon && (
                <img
                  className="account__weather-img"
                  src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                  alt={`Weather: ${weather.weather[0].main}`}
                />
              )}
              <p>{weather.main.temp} ℉</p>
            </div>
            <div className="f mt1 jcb">
              <div className="account__mobile-tab-half">
                <p>{weather.name}</p>
                {now && <p>{format(now, "EEEE h:mm bb")}</p>}
                <p>Currently: {weather.weather[0].main}</p>
              </div>
              <div className="account__mobile-tab-half">
                <p>High: &nbsp;{weather.main.temp_max} ℉</p>
                <p>Low: &nbsp;{weather.main.temp_min} ℉</p>
                <p>Humidity: &nbsp;{weather.main.humidity}%</p>
                <p>Wind: &nbsp;{weather.wind.speed} mph</p>
              </div>
            </div>
          </div>
        )}
      </MobileTabDrawer>
    </div>
  );
};

export default MobileWeather;
