import { useState, useEffect } from "react";
import fetch from "unfetch";

const getWeather = ({ customerAddress = {} }) => {
  const [isReady, setIsReady] = useState(false);
  const [weather, setWeather] = useState({});

  const { city, province, country } = customerAddress;

  useEffect(() => {
    if (city && province && country) {
      const requestWeather = async () => {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${city},${province},${country}&units=imperial&appid=${process.env.GATSBY_WEATHER_API_TOKEN}`
        );
        const weatherResult = await response.json();
        setWeather(weatherResult);
        setIsReady(true);
      };
      requestWeather();
    }
    return () => {};
  }, [customerAddress]);

  return [isReady, weather];
};

const getAirPollution = ({ customerAddress = {} }) => {
  const [isReady, setIsReady] = useState(false);
  const [airPollution, setAirPollution] = useState({});

  const { city, province, country } = customerAddress;

  useEffect(() => {
    if (city && province && country) {
      const requestWeather = async () => {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${city},${province},${country}&units=imperial&appid=${process.env.GATSBY_WEATHER_API_TOKEN}&lang=en`
        );
        const weatherResult = await response.json();

        const airPollutionRequest = await fetch(
          `https://api.openweathermap.org/data/2.5/air_pollution?lat=${weatherResult.coord.lat}&lon=${weatherResult.coord.lon}&appid=${process.env.GATSBY_WEATHER_API_TOKEN}&lang=en`
        );
        const airPollutionResult = await airPollutionRequest.json();

        setAirPollution(airPollutionResult);
        setIsReady(true);
      };
      requestWeather();
    }
    return () => {};
  }, [customerAddress]);

  return [isReady, airPollution];
};

export { getWeather, getAirPollution };
