import React, { Fragment, useEffect, useState, useRef } from "react";
import { useStoreContext } from "src/context/siteContext";
import { format } from "date-fns";
import { getWeather } from "../../hooks/useWeather";

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const Weather = ({ setCurrentTab }) => {
  const { customerAddress } = useStoreContext();
  const [now, setNow] = useState(Date());
  const [isReady, weather] = getWeather({ customerAddress });

  useEffect(() => {
    setNow(new Date());
  }, []);

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  const weatherIcon = ({ id, main }) => {
    let icon;

    switch (id) {
      case 200:
      case 201:
      case 202:
      case 210:
      case 211:
      case 212:
      case 221:
      case 230:
      case 231:
      case 232:
        icon = "/images/thunderstorm.svg";
        break;
      case 300:
      case 301:
      case 302:
      case 310:
      case 311:
      case 312:
      case 313:
      case 314:
      case 321:
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 511:
      case 520:
      case 521:
      case 522:
      case 531:
        icon = "/images/rain.svg";
        break;
      case 600:
      case 601:
      case 602:
      case 611:
      case 612:
      case 613:
      case 615:
      case 616:
      case 620:
      case 621:
      case 622:
        icon = "/images/snow.svg";
        break;
      case 701:
      case 711:
      case 721:
      case 731:
      case 741:
      case 751:
      case 761:
      case 762:
      case 772:
      case 781:
        icon = "/images/mist.svg";
        break;
      case 800:
        icon = "/images/sun.svg";
        break;
      case 801:
        icon = "/images/few-clouds.svg";
        break;
      case 802:
      case 803:
      case 804:
        icon = "/images/cloudy.svg";
        break;

      default:
        break;
    }
    return (
      <img
        className="account__weather-img"
        src={icon}
        alt={`Weather: ${main}`}
      />
    );
  };

  return (
    <div className="b--black account__sidebar-block">
      {!customerAddress && (
        <div className="account__sidebar-block--padding">
          <p>No address found.</p>
          <button
            className="button--reset"
            onClick={() => setCurrentTab("addresses")}
          >
            <span className="underline-on-hover">Set a new Address</span>
          </button>
        </div>
      )}
      {!isReady && customerAddress && (
        <div className="account__sidebar-block--padding">
          Loading Weather...
        </div>
      )}
      {isReady && weather && customerAddress && (
        <Fragment>
          <div className="account__sidebar-block--padding">
            <div className="f aic">
              {weather.weather[0].icon && weatherIcon(weather.weather[0])}
              <p>{weather.main.temp} ℉</p>
            </div>
          </div>
          <div className="account__sidebar-block--padding bt--black">
            <p>{weather.name}</p>
            {now && <p>{format(now, "EEEE h:mm bb")}</p>}
            <p>Currently: {weather.weather[0].main}</p>
          </div>
          <div className="account__sidebar-block--padding bt--black">
            <p>High: &nbsp;{weather.main.temp_max} ℉</p>
            <p>Low: &nbsp;{weather.main.temp_min} ℉</p>
            <p>Humidity: &nbsp;{weather.main.humidity}%</p>
            <p>Wind: &nbsp;{weather.wind.speed} mph</p>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Weather;
