import React from "react";
import { updateAddress, deleteAddress } from "../../api/account";
import cookie from "js-cookie";

const AddressTile = ({
  id,
  name,
  firstName,
  lastName,
  address1,
  address2,
  city,
  province,
  zip,
  customerToken,
  refreshAddresses,
  setCurrentEditingAddress,
}) => {
  const token = customerToken || cookie.get("customer_token");

  const handleDelete = async (e) => {
    e.preventDefault();

    const request = await deleteAddress({ token, id });

    const result = await request.json();

    refreshAddresses();
  };

  const handleDefault = async (e) => {
    e.preventDefault();

    const request = await updateAddress(token, null, id);

    const result = await request.json();

    refreshAddresses();
  };

  const handleEdit = () => {
    setCurrentEditingAddress({
      id,
      name,
      firstName,
      lastName,
      address1,
      address2,
      city,
      province,
      zip,
    });
  };

  return (
    <div className="b--black account__sidebar-block account__sidebar-block--padding">
      <p>{name}</p>
      <p className="mt1">
        {address1} {address2}
      </p>
      <p>
        {city}, {province} {zip}
      </p>
      <div className="mt1 f">
        <button onClick={handleEdit} className="button--reset mr1">
          <span className="underline-on-hover">Edit</span>
        </button>
        <button onClick={handleDefault} className="button--reset mr1">
          <span className="underline-on-hover">Set Default</span>
        </button>
        <button onClick={handleDelete} className="button--reset mr1">
          <span className="underline-on-hover">Delete</span>
        </button>
      </div>
    </div>
  );
};

export default AddressTile;
