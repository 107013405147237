import React, { useEffect, useState } from "react";
import { getMercuryRetrograde } from "../../hooks/useAstrology";
import cx from "classnames";
import MobileTabDrawer from "./MobileTabDrawer";

const MobileMercuryRetrograde = ({
  currentNavTab,
  setCurrentNavTab,
  setCurrentTab,
}) => {
  const [now, setNow] = useState("");
  const [isReady, isRetrograde] = getMercuryRetrograde(now);

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    setNow(formattedDate);
  }, []);

	return (
    <div className="account__mobile-tab">
      {isReady && (
        <MobileTabDrawer
          openByDefault={currentNavTab === "mercury-retrograde"}
          isClosed={currentNavTab !== "mercury-retrograde"}
          renderTrigger={({ isOpen, onClick }) => (
            <button
              onClick={() => {
                setCurrentNavTab("mercury-retrograde");
                onClick();
              }}
              className={cx("account__mobile-tab-tile f jcc aic", {
                "bb--black": currentNavTab !== "mercury-retrograde",
              })}
            >
              <img
                className="account__mobile-tab-icon"
                src="/images/icon-rx.svg"
                alt="Mercury Icon"
              />
            </button>
          )}
        >
          <p className="account__mobile-tab-content bt--black">
            Is Mercury in Retrograde? &nbsp;{isRetrograde ? " YES." : " NO."}
          </p>
        </MobileTabDrawer>
      )}
    </div>
  );
};

export default MobileMercuryRetrograde;
