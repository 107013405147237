import React, { Fragment, useEffect, useState } from "react";

import cookie from "js-cookie";
import { getHoroscope } from "../../hooks/useAstrology";

const Horoscope = () => {
  const [sign, setSign] = useState(null);
  const [isReady, horoscope] = getHoroscope(sign);

  const zodiac = (day, month) => {
      let zodiacSign = "";

      if (
        (typeof parseInt(month) === "number" && parseInt(month) === 12) ||
        month.toLowerCase() === "december" ||
        month.toLowerCase() === "dec"
      ) {
        if (day < 22) zodiacSign = "Sagittarius";
        else zodiacSign = "Capricorn";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 1) ||
        month.toLowerCase() === "january" ||
        month.toLowerCase() === "jan"
      ) {
        if (day < 20) zodiacSign = "Capricorn";
        else zodiacSign = "Aquarius";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 2) ||
        month.toLowerCase() === "february" ||
        month.toLowerCase() === "feb"
      ) {
        if (day < 19) zodiacSign = "Aquarius";
        else zodiacSign = "Pisces";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 3) ||
        month.toLowerCase() === "march" ||
        month.toLowerCase() === "mar"
      ) {
        if (day < 21) zodiacSign = "Pisces";
        else zodiacSign = "aries";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 4) ||
        month.toLowerCase() === "april" ||
        month.toLowerCase() === "apr"
      ) {
        if (day < 20) zodiacSign = "Aries";
        else zodiacSign = "Taurus";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 5) ||
        month.toLowerCase() === "may"
      ) {
        if (day < 21) zodiacSign = "Taurus";
        else zodiacSign = "Gemini";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 6) ||
        month.toLowerCase() === "june" ||
        month.toLowerCase() === "jun"
      ) {
        if (day < 21) zodiacSign = "Gemini";
        else zodiacSign = "Cancer";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 7) ||
        month.toLowerCase() === "july" ||
        month.toLowerCase() === "jul"
      ) {
        if (day < 23) zodiacSign = "Cancer";
        else zodiacSign = "Leo";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 8) ||
        month.toLowerCase() === "august" ||
        month.toLowerCase() === "aug"
      ) {
        if (day < 23) zodiacSign = "Leo";
        else zodiacSign = "Virgo";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 9) ||
        month.toLowerCase() === "september" ||
        month.toLowerCase() === "sep" ||
        month.toLowerCase() === "sept"
      ) {
        if (day < 23) zodiacSign = "Virgo";
        else zodiacSign = "Libra";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 10) ||
        month.toLowerCase() === "october" ||
        month.toLowerCase() === "oct"
      ) {
        if (day < 23) zodiacSign = "Libra";
        else zodiacSign = "Scorpio";
      } else if (
        (typeof parseInt(month) === "number" && parseInt(month) === 11) ||
        month.toLowerCase() === "november" ||
        month.toLowerCase() === "nov"
      ) {
        if (day < 22) zodiacSign = "scorpio";
        else zodiacSign = "Sagittarius";
      }

      return zodiacSign;
  };

  const handleSetBirthday = (e) => {
    e.preventDefault();

    const { date, month } = e.target;
    const zodiacSign = zodiac(Number(date.value), month.value);

    setSign(zodiacSign);

    cookie.set("customer_zodiac", zodiacSign, { expires: 365 });
  };

  useEffect(() => {
    const zodiacSign = cookie.get("customer_zodiac") || sign;
    setSign(zodiacSign);
  }, []);

  return (
    <div className="b--black account__sidebar-block">
      {!sign && (
        <Fragment>
          <p className="account__sidebar-block--padding">No Birthday Found.</p>
          <div className="account__sidebar-block--padding bt--black">
            <p>Enter your birthday for your horoscope</p>

            <form className="mt1" onSubmit={handleSetBirthday}>
              <div className="f mb1">
                <label className="mr1">
                  <input
                    type="text"
                    name="month"
                    id="month"
                    className="accounts__input py1 px1 s16 x"
                    placeholder="Month"
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="date"
                    id="date"
                    className="accounts__input py1 px1 s16 x"
                    placeholder="Day"
                  />
                </label>
              </div>
              <button type="submit" className="cta cta--black">
                Get Horoscope
              </button>
            </form>
          </div>
        </Fragment>
      )}
      {!isReady && sign && (
        <div className="account__sidebar-block--padding">
          Loading your Horoscope...
        </div>
      )}
      {isReady && horoscope && sign && (
        <Fragment>
          <div className="account__sidebar-block--padding f jcb">
            <p>{sign}</p>
            <p>{horoscope.date_range}</p>
          </div>
          <div className="account__sidebar-block--padding bt--black">
            <p>{horoscope.description}</p>
          </div>
          <div className="account__sidebar-block--padding bt--black">
            <p>Lucky Number: &nbsp; {horoscope.lucky_number}</p>
            <p>Mood: &nbsp; {horoscope.mood}</p>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Horoscope;
