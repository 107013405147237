import React from "react";
import { deleteAddress } from "../../api/account";
import cookie from "js-cookie";

const DefaultAddress = ({
  id,
  name,
  firstName,
  lastName,
  address1,
  address2,
  city,
  province,
  country,
  zip,
  customerToken,
  refreshAddresses,
  setCurrentEditingAddress,
}) => {
  const token = customerToken || cookie.get("customer_token");

  const handleDelete = async (e) => {
    e.preventDefault();

    const request = await deleteAddress({ token, id });

    const result = await request.json();

    refreshAddresses();
  };

  const handleEdit = () => {
    setCurrentEditingAddress({
      id,
      name,
      firstName,
      lastName,
      address1,
      address2,
      city,
      province,
      zip,
    });
  };

  return (
    <div className="b--black account__sidebar-block">
      <div className="account__sidebar-block--padding f jcb">
        <p>{name}</p>
        <p>Default Address</p>
      </div>
      <div className="account__sidebar-block--padding bt--black">
        <p>
          {address1} {address2}
        </p>
        <p>
          {city}, {province} {zip}
        </p>
        <div className="mt1 f">
          <button onClick={handleEdit} className="button--reset mr1">
            <span className="underline-on-hover">Edit</span>
          </button>
          <button onClick={handleDelete} className="button--reset mr1">
            <span className="underline-on-hover">Delete</span>
          </button>
        </div>
        <div className="account__sidebar-block">
          <iframe
            title="Map"
            className="account__address-map b--black"
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAPS_TOKEN}&q=${address1}+${city}+${province}+${country}+${zip}`}
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultAddress;
