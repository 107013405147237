import React, { useCallback } from 'react'
import { Link, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import { useLoads } from 'react-loads'
import reduce from 'lodash/reduce'
import {removeGraphEdgesNode} from '../../utils/removeGraphEdgesNode';
import cookie from "js-cookie"

import { RevealOnMount } from "../reveal-on-mount";
import { ErrorHandling } from 'src/utils/error'
import { login } from '../../api/account'

import { UpdateCustomer } from 'src/utils/updateCustomer'
import { setCustomerInState } from 'src/context/siteContext'

export const Login = ({ path }: { path: string }) => {

  const updateCustomerInState = setCustomerInState()
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleLogin = useCallback(
    (email, password) =>
      login({email, password})
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw new Error(res.error)
          } else {
            const orders = removeGraphEdgesNode(res.customer.orders)

            const purchasedCourses = reduce(
              orders,
              (currentList, order) => {
                const orders = reduce(
                  order.lineItems,
                  (list, lineItem) => {
                    if (
                      lineItem.variant &&
                      lineItem.variant.product.productType === "Courses"
                    ) {
                      return [...list, lineItem];
                    } else {
                      return list;
                    }
                  },
                  []
                )
                if (orders && orders.length > 0) {
                  return [...currentList, ...orders]
                } else {
                  return currentList
                }
              },
              []
            )

            cookie.set("customer_courses", purchasedCourses, { expires: 31 })
            UpdateCustomer(res, email)
            setTimeout(() => {
              updateCustomerInState()
              navigate('/account')
            }, 400)
            return null
          }
        }),
    []
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    'handleLogin',
    handleLogin as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { email, password } = form.current.elements

    load(email.value, password.value)
  }

  return (
    <RevealOnMount location={path}>
      <Helmet title='Login — District Vision' />

        <div className='account__form-container x mxa'>
          <div className='f jcb'>
            <p >Log In or Sign Up</p>

            <Link className='s14 underline active block' to='/account/forgot'>
              Forgot your password?
            </Link>

          </div>
          <form
            className='mt1'
            onSubmit={e => handleSubmit(e)}
            ref={form}
          >

            <div className='x container--s al mya'>
              {(isPending ||
                isReloading) && (
                <span>Loading</span>
              )}

            {isRejected && <ErrorHandling error={error.message} />}

              <div className='pb1 pya'>
                <label>
                  <input name='email' type='text' required={true} className='accounts__input py1 px1 s16 x' placeholder='Email Address' />
                </label>
              </div>
              <div className='mb1 pb1 pya'>
                <label>
                  <input name='password' type='password' required={true} className='accounts__input accounts__input--password py1 px1 mb1 s16 x' placeholder='Password' />
                </label>
              </div>
              <button type='submit' className='cta cta--black'>
                {(isPending ||
                  isReloading) ? (
                  <span>Loading</span>
                ): (
                  <span>Log in</span>
                )}
              </button>
            </div>



              <p className='mt1 pt1'>
                Not a member yet?
              </p>
              <Link className='block cta cta--secondary mt1' to='/account/register'>
                Sign up
              </Link>

          </form>
        </div>
    </RevealOnMount>
  )
}
