import React from "react";
import OrderTileProduct from "./OrderTileProduct";
import CourseLink from "./CourseLink";

import spacetime from "spacetime";
import reduce from "lodash/reduce";

const OrderTile = ({
  orderNumber,
  totalPrice,
  processedAt,
  statusUrl,
  lineItems = [],
}) => {
  const coursesList = reduce(
    lineItems,
    (currentList, item) => {
      const { variant } = item;

      if (
        variant &&
        variant.product &&
        variant.product.productType === "Courses"
      ) {
        return [...currentList, item];
      } else {
        return [...currentList];
      }
    },
    []
  );

  return (
    <div className="b--black account__sidebar-block">
      <div className="account__order-header">
        <div className="account__order-header-section f">
          <p className="account__order-header-text">
            Order Placed
            <br />
            {spacetime(processedAt).unixFmt("dd.MM.yyyy")}
          </p>
          <p className="">
            Total
            <br />${totalPrice.amount}
          </p>
        </div>
        <div className="account__order-header-section account__order-header-section--stretch f">
          <p className="account__order-header-text--right">
            Order # {orderNumber}
            <br />
            <a href={statusUrl} rel="noopener noreferrer" target="_blank">
              {" "}
              View Order Details{" "}
            </a>
          </p>
        </div>
      </div>
      <div className="account__order-list bt--black f">
        <div className="account__order-line-item-list">
          {lineItems.map((product) => {
            if (product.variant)
              return (
                <OrderTileProduct
                  key={product.variant.product.id}
                  statusUrl={statusUrl}
                  {...product}
                />
              );
          })}
        </div>
        <div className="account__order-line-item-links account__sidebar-block--padding x">
          <p className="pb1">&nbsp;</p>
          <div className="mt1">
            <a href={statusUrl} className="cta cta--secondary cta--account">
              View Order Details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTile;
