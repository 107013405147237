import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router"
import cookie from "js-cookie";
import cx from 'classnames'
import queryString from "query-string";
import { SwitchTransition, Transition } from "react-transition-group";
import scrollToElement from "scroll-to-element";

import {
  setCustomerInState,
  useSetCourseList,
  useStoreContext,
} from "src/context/siteContext";
import { removeGraphEdgesNode } from "../../utils/removeGraphEdgesNode";
import MediaPlayer from "../media/MediaPlayer";
import MobilePortalNav from "./MobilePortalNav";
import { Orders } from "src/components/auth/orders";
import CustomerCourses from "./CustomerCourses";
import Addresses from "./Addresses";
import Weather from "./Weather";
// import LiveFeed from "./LiveFeed";
import Horoscope from "./Horoscope";
import AirPollution from "./AirPollution";
// import NoisePollution from './NoisePollution';
import MercuryRetrograde from "./MercuryRetrograde";

import { reduce } from "lodash";

import { logout, getCustomer } from "../../api/account";

const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

const handleLogOut = (
  e: React.MouseEvent<HTMLAnchorElement>,
  updateCustomer: any
) => {
  e.preventDefault();
  const customerToken = cookie.get("customer_token");

  logout(customerToken).then(() => {
    cookie.remove("customer_token");
    cookie.remove("customer_email");
    cookie.remove("customer_firstName");
    cookie.remove("customer_defaultAddress");
    cookie.remove("customer_courses");
    setTimeout(() => {
      updateCustomer({
        customerEmail: null,
        customerName: null,
        customerToken: null,
        customerAddress: null,
      });
    }, 300);
    setTimeout(() => {
      navigate("/");
    }, 500);
  });
};

export const Portal = () => {
  const updateCustomerInState = setCustomerInState();
  const setCourses = useSetCourseList();
  const { customerToken, customerCourses = [] } = useStoreContext();
  const { search } = useLocation();

  const [currentTab, setCurrentTab] = useState("orders");
  const [orders, setOrders] = useState([]);
  // const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOrders = async (token) => {
    setIsLoading(true);
    try {
      const customerRequest = await getCustomer({ token });
      const customerResponse = await customerRequest.json();

      const { customer } = customerResponse;

      if (customer.orders && customer.orders.edges) {
        const ordersResponse = removeGraphEdgesNode(customer.orders);

        setOrders(ordersResponse);

        const purchasedCourses = reduce(
          ordersResponse,
          (currentList, order) => {
            const orders = reduce(
              order.lineItems,
              (list, lineItem) => {
                if (
                  lineItem.variant &&
                  lineItem.variant.product.productType === "Courses"
                ) {
                  return [...list, lineItem];
                } else {
                  return list;
                }
              },
              []
            );
            if (orders && orders.length > 0) {
              return [...currentList, ...orders];
            } else {
              return currentList;
            }
          },
          []
        );

        console.log("loaded courses");

        setCourses(purchasedCourses);

        cookie.set("customer_courses", purchasedCourses, { expires: 31 });

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (search) {
      const { currentTab, course } = queryString.parse(search);

      if (currentTab && !course) {
        setCurrentTab(currentTab);
      }

      if (currentTab === "courses" && course) {
        setCurrentTab("courses");
        setTimeout(() => {
          const scrollElement = document.querySelector(`#course_${course}`);
          scrollElement &&
            scrollToElement(`#course_${course}`, {
              offset: -96,
              ease: "outSine",
              duration: 750,
            });
        }, 1000);
      }
    }
  }, [search]);

  useEffect(() => {
    const token = customerToken || cookie.get("customer_token");
    handleOrders(token);
  }, []);

  return (
    <React.Fragment>
      <nav className="account__nav f aib jcb">
        <section className="f aib">
          <button
            onClick={() => {
              navigate("/account?currentTab=orders");
              setCurrentTab("orders");
            }}
            className={cx("account__nav-button", {
              active: currentTab === "orders",
            })}
          >
            Orders
          </button>
          <button
            onClick={() => {
              navigate("/account?currentTab=courses");
              setCurrentTab("courses");
            }}
            className={cx("account__nav-button", {
              active: currentTab === "courses",
            })}
          >
            Courses
          </button>
          <button
            onClick={() => {
              navigate("/account?currentTab=addresses");
              setCurrentTab("addresses");
            }}
            className={cx("account__nav-button", {
              active: currentTab === "addresses",
            })}
          >
            Saved Addresses
          </button>
        </section>
        <a
          href="#logout"
          onClick={(e) => handleLogOut(e, updateCustomerInState)}
        >
          Logout
        </a>
      </nav>

      <MobilePortalNav setCurrentTab={setCurrentTab} />

      {customerToken && (
        <section className="account__content-wrapper">
          <SwitchTransition>
            <Transition
              key={currentTab}
              mountOnEnter
              unmountOnExit
              appear
              timeout={TRANSITION_DURATION}
            >
              {(status) => (
                <section
                  className="account__content x"
                  style={{
                    ...TRANSITION_STYLES.default,
                    ...TRANSITION_STYLES[status],
                  }}
                >
                  {currentTab === "orders" && (
                    <Orders isLoading={isLoading} orders={orders} />
                  )}
                  {currentTab === "courses" && (
                    <CustomerCourses isLoading={isLoading} />
                  )}
                  {currentTab === "addresses" && <Addresses />}
                </section>
              )}
            </Transition>
          </SwitchTransition>

          <aside className="account__sidebar">
            <Weather setCurrentTab={setCurrentTab} />
            {/* <LiveFeed /> */}
            <Horoscope />
            <AirPollution setCurrentTab={setCurrentTab} />
            {/* <NoisePollution  setCurrentTab={setCurrentTab}/> */}
            <MercuryRetrograde />
          </aside>
        </section>
      )}
      <MediaPlayer />
    </React.Fragment>
  );
};
