import React, { Fragment } from "react";
import CourseTile from "./CourseTile";
import { Link } from "../link";

import { useStoreContext } from "src/context/siteContext";

const CustomerCourses = React.memo(({ isLoading }) => {
  const { customerCourses } = useStoreContext();
  return (
    <Fragment>
      {isLoading && "Loading..."}

      {(!customerCourses || customerCourses.length === 0) && !isLoading && (
        <p className="b--black account__sidebar-block--padding">
          No courses purchased.
          <Link
            className="ml1"
            type="internalLink"
            to="/shop/mindfulness-courses"
          >
            <span className="underline-on-hover">View courses here</span>
          </Link>
        </p>
      )}

      {customerCourses && customerCourses.length > 0 && !isLoading && (
        <Fragment>
          {customerCourses.map((course) => (
            <CourseTile key={course.title} {...course} />
          ))}
        </Fragment>
      )}
    </Fragment>
  );
});

function areEqual(prevProps, nextProps) {
  return prevProps === nextProps;
}

// CustomerCourses.whyDidYouRender = true;

export default React.memo(CustomerCourses, areEqual);
