import fetch from "unfetch";

export const logout = async ({customerToken}) => {
	return await fetch('/.netlify/functions/logout', {
		method: 'POST',
		body: JSON.stringify({
			accessToken: customerToken
		})
	})
}

export const login = async ({email, password}) => {
	return await fetch(`/.netlify/functions/login`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
        }),
	})
}

export const getCustomer = async ({token}) => {
	return await fetch(`/.netlify/functions/customer`, {
        method: "POST",
        body: JSON.stringify({
          token
        })
	})
}

export const createAddress = async ({token, isDefault, address}) => {
	return await  fetch('/.netlify/functions/address-new', {
		method: 'POST',
		body: JSON.stringify({
			token,
			isDefault,
			address,
		})
	})
}

export const deleteAddress = async ({token, id}) => {
	return await  fetch('/.netlify/functions/address-delete', {
		method: 'POST',
		body: JSON.stringify({
			token,
			addressId: id
		})
	})
}

export const updateAddress = async (token, address, id, isDefault) => {
	return await  fetch('/.netlify/functions/address-update', {
		method: 'POST',
		body: JSON.stringify({
			token,
			address,
			addressId: id,
			isDefault
		})
	})
}

export const getNoisePollution = async ({address1, city, province, country, zip}) => {
	return await  fetch('/.netlify/functions/noise-pollution', {
		method: 'POST',
		body: JSON.stringify({
			address1,
			city,
			province,
			country,
			zip
		})
	})
}