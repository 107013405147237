import React, { Fragment, useState, useRef } from "react";
import FormSelect from "./FormSelect";

import { useStoreContext } from "src/context/siteContext";
import { updateAddress } from "../../api/account";
import countryOptions from "../../data/countryOptions.json";
import find from "lodash/find";
import cx from "classnames";

const COUNTRIES = countryOptions.map((c) => c.country);

const AddressEdit = ({
  currentEditingAddress = {},
  setCurrentEditingAddress,
  refreshAddresses,
}) => {
  const { customerToken, customerAddress = {} } = useStoreContext();
  const form = useRef(null);
  const [provinces, setProvinces] = useState(
    countryOptions[0].provinces.map((p) => p[0])
  );
  const [selectedProvince, setSelectedProvince] = useState(
    customerAddress?.province
  );
  const [selectedCountry, setSelectedCountry] = useState(
    customerAddress?.country
  );
  const [formError, setFormError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, address1, address2, city, zip, isDefault } =
      form.current.elements;

    if (
      !firstName ||
      !lastName ||
      !address1 ||
      !address2 ||
      !city ||
      !selectedCountry ||
      !selectedProvince ||
      !zip
    ) {
      setFormError("Please add all values");
      return;
    }

    setFormError(null);

    try {
      const submitRequest = await updateAddress(
        customerToken,
        {
          firstName: firstName.value,
          lastName: lastName.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          country: selectedCountry,
          province: selectedProvince,
          zip: zip.value,
        },
        currentEditingAddress.id,
        isDefault.checked
      );

      const submitResult = await submitRequest.json();

      const { address } = submitResult;

      const { customerAddressUpdate, customerDefaultAddressUpdate } = address;

      console.log(submitResult);

      if (customerAddressUpdate) {
        // Create Non-Default Address
        console.log(customerAddressUpdate);

        const { customerAddress, customerUserErrors = [] } =
          customerAddressUpdate;

        if (customerUserErrors && customerUserErrors.length > 0) {
          console.error(customerUserErrors[0].message);
          setSubmitError(customerUserErrors[0].message);
        }

        if (customerAddress) {
          console.log(customerAddress);
          setSubmitError(null);
          refreshAddresses();
          setCurrentEditingAddress(null);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCountryChange = (value) => {
    const updatedProvinces = find(
      countryOptions,
      (option) => option.country === value
    );
    updatedProvinces &&
      setProvinces(updatedProvinces.provinces.map((p) => p[0]));

    setSelectedCountry(value);
  };

  const handleProvinceChange = (value) => {
    setSelectedProvince(value);
  };

  const underlay = (
    <div
      className={cx(
        "account__underlay fix fill top bottom left right bg-black",
        {
          active:
            currentEditingAddress && Object.keys(currentEditingAddress).length,
        }
      )}
      onClick={() => {
        setCurrentEditingAddress(null);
      }}
    />
  );

  const formElement = (
    <div
      className={cx(
        "account__form-container account__form-edit mxa bg-white x",
        {
          active:
            currentEditingAddress && Object.keys(currentEditingAddress).length,
        }
      )}
    >
      <div className="b--black p1">
        {submitError && <p className="error">{submitError}</p>}
        {formError && <p className="error">{formError}</p>}
        <p className="mt1">Edit Address</p>
        <form className="mt1" onSubmit={handleSubmit} ref={form}>
          <div>
            <label>
              <input
                name="firstName"
                type="text"
                required={true}
                className="accounts__input py1 px1 s16 x"
                placeholder="First Name"
                defaultValue={
                  currentEditingAddress && currentEditingAddress.firstName
                }
              />
            </label>
          </div>
          <div className="mt1">
            <label>
              <input
                name="lastName"
                type="text"
                required={true}
                className="accounts__input py1 px1 s16 x"
                placeholder="Last Name"
                defaultValue={
                  currentEditingAddress && currentEditingAddress.lastName
                }
              />
            </label>
          </div>
          <div className="mt1">
            <label>
              <input
                name="address1"
                type="text"
                required={true}
                className="accounts__input py1 px1 s16 x"
                placeholder="Address"
                defaultValue={
                  currentEditingAddress && currentEditingAddress.address1
                }
              />
            </label>
          </div>
          <div className="mt1">
            <label>
              <input
                name="address2"
                type="text"
                className="accounts__input py1 px1 s16 x"
                placeholder="Apt, Unit, Suite, etc."
                defaultValue={
                  currentEditingAddress && currentEditingAddress.address2
                }
              />
            </label>
          </div>
          <div className="mt1">
            <label>
              <input
                name="city"
                type="text"
                required={true}
                className="accounts__input py1 px1 s16 x"
                placeholder="City"
                defaultValue={
                  currentEditingAddress && currentEditingAddress.city
                }
              />
            </label>
          </div>
          <div className="mt1">
            <FormSelect
              name="country"
              placeholder="Country*"
              onChange={handleCountryChange}
              value={selectedCountry}
              className={cx("mt1", {
                error: formError,
              })}
              options={COUNTRIES}
            />
          </div>
          <div className="mt1">
            <FormSelect
              name="province"
              placeholder="Province*"
              onChange={handleProvinceChange}
              value={selectedProvince}
              className={cx("mt1", {
                error: formError,
              })}
              options={provinces}
            />
          </div>
          <div className="mt1">
            <label>
              <input
                name="zip"
                type="text"
                required={true}
                className="accounts__input py1 px1 s16 x"
                placeholder="Zip"
                defaultValue={
                  currentEditingAddress && currentEditingAddress.zip
                }
              />
            </label>
          </div>
          <div className="f aic mt1">
            <div className="account__form-check">
              <input
                type="checkbox"
                id="isDefault"
                name="isDefault"
                className="account__form-checkbox-input"
              />
              <span className="account__form-checkbox-mark" />
            </div>
            <label htmlFor="isDefault">Set as Default Address</label>
          </div>
          <button type="submit" className="cta cta--black mt1">
            Update Address
          </button>
        </form>
      </div>
    </div>
  );

  return (
    <Fragment>
      {underlay}
      {formElement}
    </Fragment>
  );
};
export default AddressEdit;
