import React, { useMemo, useState, useEffect } from "react";
import { Logotype } from "../logotype/logotype";
import {
  useToggleMediaPlayer,
  useSetCourseMedia,
  useSetCurrentTrack,
  useStoreContext,
} from "../../context/siteContext";

import { getCourseInfo } from "../../hooks/useProductLookup";
import { decode } from "shopify-gid";
import BlockContent from "@sanity/block-content-to-react";

import cx from "classnames";
import cookie from "js-cookie";

const CourseTile = ({ title, variant }) => {
  const decodedCourse = variant.product.id.replace(
    "gid://shopify/Product/",
    ""
  );

  const [isReady, courseInfo] = getCourseInfo(Number(decodedCourse));

  const [courseType, setCourseType] = useState(undefined);
  const [courseInstructor, setCourseInstructor] = useState(undefined);
  const [courseDescription, setCourseDescription] = useState(undefined);
  const [courseCover, setCourseCover] = useState(undefined);
  const [courseContent, setCourseContent] = useState(undefined);

  const { currentTrack } = useStoreContext();
  const setCurrentTrack = useSetCurrentTrack();
  const setCourseMedia = useSetCourseMedia();
  const setMediaPlayerOpen = useToggleMediaPlayer();

  console.log(variant);

  useEffect(() => {
    if (courseInfo) {
      setCourseType(
        courseInfo.courseType !== courseType
          ? courseInfo.courseType
          : courseType
      );
      setCourseInstructor(
        courseInfo.courseInstructor !== courseInstructor
          ? courseInfo.courseInstructor
          : courseInstructor
      );
      setCourseDescription(
        courseInfo.courseDescription !== courseDescription
          ? courseInfo.courseDescription
          : courseDescription
      );
      setCourseCover(
        courseInfo.courseCover !== courseCover
          ? courseInfo.courseCover
          : courseCover
      );
      setCourseContent(
        courseInfo.courseContent !== courseContent
          ? courseInfo.courseContent
          : courseContent
      );
    }

    return () => {
      setCourseType(null);
      setCourseInstructor(null);
      setCourseDescription(null);
      setCourseCover(null);
      setCourseContent(null);
    };
  }, [courseInfo]);

  return (
    <React.Fragment>
      {isReady &&
        (courseType ||
          courseInstructor ||
          courseDescription ||
          courseCover ||
          courseContent) && (
          <div
            id={`course_${decodedCourse}`}
            className="b--black account__sidebar-block"
          >
            <p className="account__sidebar-block--padding">{title}</p>
            <div className="course__line-item f bt--black">
              {courseCover && courseCover.url && (
                <img
                  src={courseCover.url}
                  className="course__line-item-img x"
                  alt={title}
                />
              )}
              <div className="course__line-item-info account__sidebar-block--padding">
                <Logotype isCourse />
                <div className="course__line-item-title">
                  {courseInstructor && <p>{courseInstructor}</p>}
                  <p>{title}</p>
                </div>
                {courseDescription && (
                  <div className="course__line-item-description">
                    <BlockContent blocks={courseInfo.courseDescription} />
                  </div>
                )}
                <div className="course__line-item-cta">
                  <button
                    className="cta cta--black cta--account f jcc aic"
                    onClick={() => {
                      setCourseMedia(courseType, courseContent);
                      setCurrentTrack(courseContent[0]);
                      setMediaPlayerOpen(true);
                    }}
                  >
                    Start Course
                  </button>
                </div>
                {courseContent && courseContent.length > 0 && (
                  <div className="course__line-item-content">
                    <p>Lesson Plan</p>
                    <ul className="course__line-item-content-list">
                      {courseContent.map((item, index) => {
                        const savedProgressCookie = cookie.get(
                          `district-vision__course-track-${
                            item.id || item._key
                          }`
                        );
                        const parsedProgress =
                          savedProgressCookie &&
                          JSON.parse(savedProgressCookie);

                        return (
                          <li
                            onClick={() => {
                              setCourseMedia(courseType, courseContent);
                              setCurrentTrack(item);
                              setMediaPlayerOpen(true);
                            }}
                            className={cx("course__line-item-index", {
                              "course__line-item-index--active":
                                currentTrack &&
                                item.title === currentTrack.title,
                            })}
                            key={item._key}
                          >
                            <span className="mr1">{index + 1}.</span>
                            <span>{item.title}</span>
                            <div className="ml1 c-red course__line-item-progress">
                              <span
                                className="ml1 c-red"
                                id={`track_${item.id || item._key}`}
                              >
                                {parsedProgress && (
                                  <React.Fragment>
                                    {parsedProgress.currentProgress < 99
                                      ? `${parsedProgress.currentProgress}% Completed`
                                      : parsedProgress.currentProgress
                                      ? "Complete"
                                      : ""}
                                  </React.Fragment>
                                )}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps === nextProps;
}

// CourseTile.whyDidYouRender = true;

export default React.memo(CourseTile, areEqual);
